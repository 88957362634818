/**
 * @generated SignedSource<<c8d1fbff8570b04d3e41f5f384f82d32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FavoritesItemProvider_item$data = {
  readonly serviceId: string | null;
  readonly " $fragmentType": "FavoritesItemProvider_item";
};
export type FavoritesItemProvider_item$key = {
  readonly " $data"?: FavoritesItemProvider_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesItemProvider_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FavoritesItemProvider_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "a8934b824610d50aad3b8e9f4507330b";

export default node;
