import {
    useRef,
    useState,
    useEffect,
    FC,
    ReactNode,
    RefObject,
    useDeferredValue,
    useMemo,
} from 'react';
import { graphql, useFragment, useRelayEnvironment, fetchQuery } from 'react-relay';
import { addToEngagedItems } from 'dibs-buyer-layout/exports/engagedItems';

import { FavoritesItemProvider_item$key } from './__generated__/FavoritesItemProvider_item.graphql';
import { FavoritesItemProvider_user$key } from './__generated__/FavoritesItemProvider_user.graphql';
import { FavoritesItemProviderQuery } from './__generated__/FavoritesItemProviderQuery.graphql';

export type FavoritesItemPropsType = {
    isFolderVisible?: boolean;
    showFolderPlusIcon?: boolean;
    onFavorited?: () => void;
    onUnFavorited?: () => void;
    onFolderActionStart?: () => void;
    onFolderActionEnd?: () => void;
};

type Props = {
    item: FavoritesItemProvider_item$key | null | undefined;
    user: FavoritesItemProvider_user$key | null | undefined;
    itemId?: string;
    userId?: string;
    applyMouseEvents?: boolean;
    className?: string;
    children: (args: FavoritesItemPropsType) => ReactNode;
};

const FavoritesItemProvider: FC<Props> = ({
    item: itemRef,
    user: userRef,
    itemId,
    userId,
    applyMouseEvents = false,
    className,
    children,
}) => {
    const item = useFragment(
        graphql`
            fragment FavoritesItemProvider_item on Item {
                serviceId
            }
        `,
        itemRef
    );
    const user = useFragment(
        graphql`
            fragment FavoritesItemProvider_user on User {
                canShowFolder
            }
        `,
        userRef
    );

    const tileElementRef: RefObject<HTMLDivElement> = useRef(null);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [isHeartFilled, setIsHeartFilled] = useState(false);
    const [isFolderAction, setIsFolderAction] = useState(false);
    const [showFetchedUserFolder, setShowFetchedUserFolder] = useState(false);

    // not sure why this works, but this prevents mouse leave event from briefly removing the
    // folder icon, causing the modal to never open
    // todo: figure out why mouseleave event is triggered.
    const deferredIsMouseOver = useDeferredValue(isMouseOver);

    // In case userRef can be passed this should be a prefered method and will avoid fetching additional user query.
    // But in some cases userRef is not available (is null) so to get folder visibility permissions we do fetch a user.
    const fetchUserFolderVisibility = applyMouseEvents && userId && !userRef;
    const environment = useRelayEnvironment();

    useEffect(() => {
        if (fetchUserFolderVisibility) {
            const subscribtion = fetchQuery<FavoritesItemProviderQuery>(
                environment,
                graphql`
                    query FavoritesItemProviderQuery($userId: String!) {
                        viewer {
                            user(userId: $userId) {
                                canShowFolder
                            }
                        }
                    }
                `,
                {
                    userId,
                },
                {
                    networkCacheConfig: { force: false },
                    fetchPolicy: 'store-or-network',
                }
            ).subscribe({
                next: ({ viewer }) => {
                    setShowFetchedUserFolder(!!viewer?.user?.canShowFolder);
                },
            });

            return () => {
                subscribtion.unsubscribe();
            };
        }

        return () => {};
    }, [fetchUserFolderVisibility, userId, environment, setShowFetchedUserFolder]);

    // We check if heart icon is filled or not by data-tn attribute
    // instead of refetching on mouse over to check if item is favorited.
    const isFavoritesHeartFilled = (): boolean =>
        tileElementRef?.current
            ?.querySelector(`[id=${item?.serviceId || itemId || ''}-heart]`)
            ?.getAttribute('data-tn')
            ?.includes('saved') || false;

    const mouseEventProps = applyMouseEvents
        ? {
              onMouseEnter: () => {
                  setIsMouseOver(true);
                  setIsHeartFilled(isFavoritesHeartFilled());
              },
              onMouseLeave: () => setIsMouseOver(false),
          }
        : {};

    // We do get folder visibility info either from user fragment or separatelly fetched user.
    const showFolder = !!user?.canShowFolder || !!showFetchedUserFolder;

    const isFolderVisible = useMemo(() => {
        return (isHeartFilled || showFolder) && (deferredIsMouseOver || isFolderAction);
    }, [isHeartFilled, showFolder, deferredIsMouseOver, isFolderAction]);

    const ensuredItemId = itemId || item?.serviceId || '';
    const childrenProps: FavoritesItemPropsType = useMemo(() => {
        if (applyMouseEvents) {
            return {
                isFolderVisible,
                showFolderPlusIcon: true,
                onFavorited: () => {
                    setIsHeartFilled(true);
                    addToEngagedItems(ensuredItemId);
                },
                onUnFavorited: () => setIsHeartFilled(false),
                onFolderActionStart: () => setIsFolderAction(true),
                onFolderActionEnd: () => setIsFolderAction(false),
            };
        } else {
            return { isFolderVisible: true };
        }
    }, [applyMouseEvents, isFolderVisible, ensuredItemId]);

    return (
        <div className={className} ref={tileElementRef} {...mouseEventProps}>
            {children(childrenProps)}
        </div>
    );
};

export default FavoritesItemProvider;
